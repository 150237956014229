export const USER_FAV_REQUEST = "USER_FAV_REQUEST";
export const USER_FAV_SUCCESS = "USER_FAV_SUCCESS";
export const USER_FAV_FAILURE = " USER_FAV_FAILURE";

export const USER_ADD_FAVORITE_REQUEST = "USER_ADD_FAVORITE_REQUEST";
export const USER_ADD_FAVORITE_SUCCESS = "USER_ADD_FAVORITE_SUCCESS";
export const USER_ADD_FAVORITE_FAILURE = "USER_ADD_FAVORITE_FAILURE";

export const USER_DELETE_FAVORITE_REQUEST = "USER_DELETE_FAVORITE_REQUEST";
export const USER_DELETE_FAVORITE_SUCCESS = "USER_DELETE_FAVORITE_SUCCESS";
export const USER_DELETE_FAVORITE_FAILURE = "USER_DELETE_FAVORITE_FAILURE";
