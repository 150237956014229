export const ADMIN_USERS_REQUEST = "ADMIN_USERS_REQUEST";
export const ADMIN_USERS_SUCCESS = "ADMIN_USERS_SUCCESS";
export const ADMIN_USERS_FAILURE = "ADMIN_USERS_FAILURE";

export const ADMIN_USERS_DELETE_REQUEST = "ADMIN_USERS_DELETE_REQUEST";
export const ADMIN_USERS_DELETE_SUCCESS = "ADMIN_USERS_DELETE_SUCCESS";
export const ADMIN_USERS_DELETE_FAILURE = "ADMIN_USERS_DELETE_FAILURE";

export const ADMIN_ORDERS_REQUEST = "ADMIN_ORDERS_REQUEST";
export const ADMIN_ORDERS_SUCCESS = "ADMIN_ORDERS_SUCCESS";
export const ADMIN_ORDERS_FAILURE = "ADMIN_ORDERS_FAILURE";

export const ADMIN_PRODUCT_DELETE_REQUEST = "ADMIN_PRODUCT_DELETE_REQUEST";
export const ADMIN_PRODUCT_DELETE_SUCCESS = "ADMIN_PRODUCT_DELETE_SUCCESS";
export const ADMIN_PRODUCT_DELETE_FAILURE = "ADMIN_PRODUCT_DELETE_FAILURE";

export const ADMIN_PRODUCT_UPDATE_REQUEST = "ADMIN_PRODUCT_UPDATE_REQUEST";
export const ADMIN_PRODUCT_UPDATE_SUCCESS = "ADMIN_PRODUCT_UPDATE_SUCCESS";
export const ADMIN_PRODUCT_UPDATE_FAILURE = "ADMIN_PRODUCT_UPDATE_FAILURE";

export const ADMIN_PRODUCT_CREATE_REQUEST = "ADMIN_PRODUCT_CREATE_REQUEST";
export const ADMIN_PRODUCT_CREATE_SUCCESS = "ADMIN_PRODUCT_CREATE_SUCCESS";
export const ADMIN_PRODUCT_CREATE_FAILURE = "ADMIN_PRODUCT_CREATE_FAILURE";
